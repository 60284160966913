import React, { useState } from "react";
import {

  Form,
  Input,
  Modal,
    Select,
  Table,

} from "antd";

import "./TableComponent.scss";

import Pagination from "../../item/Pagination/Pagination";

// Define the types for the props
type ColumnType = {
  title: string;
  dataIndex: string;
  // Add more column properties if needed
};

type DataType = {
  key: string | number;
  [key: string]: any; // Allow any other properties
};

type Props = {
  columns: ColumnType[];
  data: DataType[];
  totalPage?: number;
  onChangePage: (pageIndex: number, pageSize: number) => void;
};



const TableComponent = ({ columns, data, onChangePage, totalPage }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [customPageSizeInput, setCustomPageSizeInput] = useState("");
  const [params, setParams] = useState({
    pageSize: 10,
    pageIndex: 1,
  });

  const showModalSelect = () => {
    setIsModalSelect(true);
  };

  const handleSelectChange = (value: any) => {
    if (value === "openModal") {
      showModalSelect();
    } else {
      setParams({
        ...params,
        pageSize: value,
      });
      onChangePage(params.pageIndex, value);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePageChange = (pageIndex: number, pageSize: number) => {
    setParams({
      ...params,
      pageIndex,
    });
    onChangePage(pageIndex, params.pageSize);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCustomPageSizeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCustomPageSizeInput(value);
  };

  const handleOkSelect = () => {
    if (customPageSizeInput === "") {
      return;
    }
    const newPageSize = parseInt(customPageSizeInput);
    if (!isNaN(newPageSize)) {
      let updatedPageSize = newPageSize;

      if (typeof totalPage !== "undefined" && newPageSize > totalPage) {
        updatedPageSize = totalPage;
      }

      setParams({
        ...params,
        pageSize: updatedPageSize,
      });

      onChangePage(params.pageIndex, updatedPageSize);
    }
    setIsModalSelect(false);
  };

  const handleCancelSelect = () => {
    setIsModalSelect(false);
  };

  return (
    <div className="tableComponent">
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <div className="table__footer">
        <div className="table__footer__col">
          <p className="table__footer__col__title">Số hàng mỗi trang</p>
          <Select
            className="table__footer__select"
            style={{ width: 58 }}
            value={params.pageSize}
            onChange={handleSelectChange}
            bordered={false}
            suffixIcon={null}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={15}>15</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value="openModal">Nhập</Select.Option>
          </Select>
        </div>

        <Pagination
          current={params.pageIndex}
          total={totalPage}
          pageSize={params.pageSize}
          onChange={handlePageChange}
        />
      </div>
      <Modal
        centered
        open={isModalSelect}
        onOk={handleOkSelect}
        onCancel={handleCancelSelect}
        className="modal__product modal__selectPagination"
        okType={"danger"}
      >
        <Form
          name="basic"
          requiredMark={"optional"}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Số item trên trang"
            name="username"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số!",
              },
              {
                validator: (_, value) => {
                  const numberValue = parseInt(value, 10);

                  if (isNaN(numberValue) || numberValue <= 0) {
                    return Promise.reject("Vui lòng nhập số lớn hơn 0");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              className="modal__selectPagination__input"
              placeholder="Số item"
              value={customPageSizeInput}
              onChange={handleCustomPageSizeInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TableComponent;
