import React, { useState } from "react";
import { Button, Col, Form, Input, Row, } from "antd";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import Loading from "../../global/item/Loading/loading";

import authService from "../../service/auth";
import UseRespon from "../../../hook/useResponsive";
import signInAnimation from "../../../accset/json/signInJson.json";
import iconLogo from "../../../accset/logoApp.png";
import "./SignIn.scss";
import { openNotification } from "../../global/item/Notification/Notification";
import { useLocation, useNavigate } from "react-router-dom";

function ResetPass() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate(); // Add this
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");
  const onFinish = async (values: any) => {
    if (token === null) {
      throw new Error("Token is required");
    }
    const data = {
      newPassword: values.password,
      token: token,
    };

    try {
      setLoading(true);
       await authService.resetPassword(data);
      openNotification({
        type: "success",
        message: t("login.success"),
        description: t("login.messSuccess"),
      });
      navigate("/sign-in");
     
    } catch (error) {
      setLoading(false);
      openNotification({
        type: "error",
        message: t("login.failure"),
        description: t("login.mesFailure"),
      });
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ background: "#f2f2f2" }}>
          <Row style={{ height: "100vh" }}>
            {isMobile ? null : (
              <Col xl={16}>
                <div
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    margin: "10px 4px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 20,
                      height: "100%",
                      background: `url(${iconLogo}) no-repeat left center`,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 90px)",
                  }}
                >
                  <Lottie
                    animationData={signInAnimation}
                    style={{ width: 550 }}
                  />
                </div>
              </Col>
            )}
            <Col xl={8} xs={24} className="signInRight">
              <h2 className="signInRight--title">Welcome</h2>
              <p
                style={{
                  paddingTop: 10,
                  paddingBottom: 3,
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                You forget your password?
              </p>
              <span
                style={{
                  paddingBottom: 40,
                  color: "rgba(9, 2, 2, 0.5)",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Don't worry, please enter your email so we can assist you in
                updating your password
              </span>

              <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                // initialValues={initialValues}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  className="formItem"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input className="form-input" placeholder="" />
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                  </div>
                </Form.Item>

                <Form.Item
                  className="formItem"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <div className="form-field">
                    <Input className="form-input" placeholder="" />
                    <label htmlFor="password" className="form-label">
                      Confirm Password
                    </label>
                  </div>
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    height: 46,
                    marginTop: 20,
                    fontWeight: 600,
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default ResetPass;
