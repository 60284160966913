import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import vocab from "../../service/vocab";
import { VocabState } from "../../@type/vocab";


export const getVocabByUserId = createAsyncThunk(
  "get/getVocabByUserId",
  async (id: any) => {
    const { data } = await vocab.getById(id);
    return data;
  }
);
export const getVocab = createAsyncThunk(
  "get/getVocab",
  async (params: any) => {
    const { data } = await vocab.get(params);
    return data;
  }
);
export const createVocab = createAsyncThunk(
  "get/createVocab",
  async (formData: FormData) => {
    const { data } = await vocab.post(formData);
    return data;
  }
);

const initialState: VocabState = {
  vocabList: [],
 

};

const wordformSlice = createSlice({
  name: "vocab",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVocab.fulfilled, (state, action) => {
      state.vocabList = action.payload;
    });
    builder.addCase(getVocab.fulfilled, (state, action) => {

      state.vocabList = action.payload.vocabs;

    });
    builder.addCase(getVocabByUserId.fulfilled, (state, action) => {
      state.vocabList = action.payload.vocabs;
      state.vocabList = action.payload.vocabs;

    });
  },
});

export default wordformSlice.reducer;
