import React, { useCallback, useEffect, useState } from "react";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import TableComponent from "../../global/Custom/TableComponent/TableComponent";
import { useTranslation } from "react-i18next";
import { Col, Input, Row, Select } from "antd";
import { dispatch, useSelector } from "../../redux/store";
import { getVocab } from "../../redux/action/vocab";
import { getWordform } from "../../redux/action/grammar/wordform";
import UseRespon from "../../../hook/useResponsive";
import CardComponent from "../../global/Custom/CardComponent/CardComponent";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

function VocabList() {
  const { t } = useTranslation();
  const { isMobile } = UseRespon();
  const { vocabList } = useSelector((state) => state.vocab);
  const { wordformList } = useSelector((state) => state.wordform);
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getVocab({}));
    dispatch(getWordform({}));
  }, []);

  const onChangePage = (pageIndex: number, pageSize: number) => {
    console.log(`Page: ${pageIndex}, PageSize: ${pageSize}`);
  };

  const columns = [
    {
      title: t('vocab'),
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: t('wordform'),
      dataIndex: "category",
      key: "category",
      width: "20%",
    },
    {
      title: t('transcrip'),
      dataIndex: "transcription",
      key: "transcription",
      width: "20%",
    },
    {
      title: t("meaning"),
      dataIndex: "meaning",
      key: "meaning",
      width: "20%",
    },
  ];

  // Filtering logic
  const filteredData = vocabList.filter((item: any) => {
    const matchesSearchTerm = item.name.toLowerCase().includes(value.toLowerCase());
    const matchesWordform = selectedValues.length === 0 || item.category.some((categoryId: string) => selectedValues.includes(categoryId));
    return matchesSearchTerm && matchesWordform;
  });
  const data = filteredData.map((item: any) => {
    // Map over item.category array to get names
    const categoryNames = item.category
      .map((categoryId: string) => {
        const category = wordformList.find((wordform: any) => wordform._id === categoryId);
        return category ? category.name : 'Unknown';
      })
      .join(', '); // Join names with a comma

    return {
      key: item._id, 
      name: item.name,
      category: categoryNames, 
      transcription: item.transcription,
      meaning: item.meaning,
    };
  });
  const options = wordformList.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };


  const handleChange = useCallback((e:any) => {
    setValue(e.target.value);
  
  }, []);
  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);
  return (
    <div>
      <TitleComponent
        title={t("vocab")}
        type="primary"
        data={t("add")}
        to="/Vocab/Create"
      />
      
      <Row gutter={[20, 12]}>
        <Col xl={12} xs={24}>
        <Input
          value={value}
          onChange={handleChange}
          placeholder={isFocused ? t('searchItem') : ""}
          prefix={
            <SearchOutlined
              style={{
                color: isFocused ? 'blue' : 'black',
              }}
            />
          }
          style={{
            width: UseRespon().isMobile ? "100%" : "100%",
            boxShadow: "none",
            border: "none",
            borderRadius: 0,
            borderBottom: isFocused ? "1px solid #d9d9d9" : "none",
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        </Col>
        <Col xl={12} xs={24}>
          <Row gutter={{ xs: 12, xl: 20 }}>
            <Col xl={12} xs={12}>
              <Select
                mode="multiple"
                value={selectedValues}
                placeholder="Chọn từ loại"
                className="selectForm"
                style={{ width: "100%" }}
                onChange={handleSelectChange}
              >
                {options.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={12} xs={12}>
              <Select
                mode="multiple"
                placeholder="Chọn chủ đề"
                className="selectForm"
                style={{ width: "100%" }}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "Yiminghe" },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isMobile ? <CardComponent />:(
        <TableComponent
          columns={columns}
          data={data}
          totalPage={1}
          onChangePage={onChangePage}
        />
      )}
    </div>
  );
}

export default VocabList;
