import { AxiosResponse } from "axios";
import { getAsync, postAsync } from "../client";

class WordformService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/category', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; 
    }
  }

  async post(formData: FormData): Promise<AxiosResponse> {
    return await postAsync('/category', formData);
  }
}

// Create an instance of the WordformService class
const wordformServiceInstance = new WordformService();

// Export the instance
export default wordformServiceInstance;
