import { Button } from "antd";
import React from "react";

type Props = {
  btnCancel: string;
  btnContinue: string;
  onCancelClick?: () => void;
  onContinueClick?: () => void;
  
};

function ButtonBottom({
  btnCancel,
  btnContinue,
  onCancelClick,
  onContinueClick,
}: Props) {
  return (
    <div
      style={{
        background: "#fff",
        borderRadius: 10,
        boxShadow: `var(--box-shadow)`,
        marginTop: 24,
        display: "flex",
        gap: 16,
        justifyContent: "end",
        padding: 14,
      }}
    >
      <Button className="buttonShared" onClick={onCancelClick}>
        {btnCancel}
      </Button>
      <Button className="buttonShared" type="primary" onClick={onContinueClick} htmlType="submit">
        {btnContinue}
      </Button>
    </div>
  );
}

export default ButtonBottom;
