import { AxiosResponse } from "axios";
import { postAsync } from "./client";

class AuthService {
    login = async (loginData: { email: string; password: string; authMethod: string }): Promise<AxiosResponse> => {
        return await postAsync('/user/login', loginData);
    }

    
    loginGoogle = async (loginData: { email: string; authMethod: "google";username:string }): Promise<AxiosResponse> => {
        return await postAsync('/user/login', loginData);
    }


    register = async (data: {
        username: string;
        email: string;
        password: number;
        authMethod:string
    }): Promise<AxiosResponse> => {
        return await postAsync('/user/register', data);
    };
    verify = async (data: { token: string, otp: string }): Promise<AxiosResponse> => {
        return await postAsync('/user/verify-otp', data);
    };
    forgotPassword = async (data: { email: string }): Promise<AxiosResponse> => {
        return await postAsync('/user/forgot-password', data);
    }
    resetPassword = async (data: { token: string,newPassword:string }): Promise<AxiosResponse> => {
        return await postAsync('/user/reset-password', data);
    }
}

const authService = new AuthService();

export default authService; 
