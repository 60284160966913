// LottieAvatar.tsx
import React from 'react';

import animationData from '../../../../accset/json/avatar.json'; // Path to your Lottie file
import Lottie from 'lottie-react';

const LottieAvatar: React.FC<{ size: number }> = ({ size }) => {


  return (
    <div style={{ width: size, height: size }}>
           <Lottie animationData={animationData}/>
    </div>
  );
};

export default LottieAvatar;
