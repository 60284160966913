import { useEffect, useState } from 'react';
import { useLanguage } from './Language'; // Adjust the path as needed

const useSpeechSynthesis = () => {
  const { language } = useLanguage(); // Use the custom hook to access context
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [voiceSelected, setVoiceSelected] = useState<SpeechSynthesisVoice | null>(null);

  useEffect(() => {
    const updateVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);

      // Log details about each voice for debugging
      availableVoices.forEach(voice => {
        console.log(`Name: ${voice.name}, Lang: ${voice.lang}`);
      });

      // Set the selected voice based on the current language
      const selectedVoice = availableVoices.find(voice => {
        switch (language) {
          case 'vi':
            return voice.lang === 'vi-VN'; // Vietnamese
          case 'en':
            return voice.lang === 'en-US'; // English
          case 'ko':
            return voice.lang === 'ko-KR'; // Korean
          default:
            return voice.lang === 'en-US'; // Default to English if language is not recognized
        }
      });

      setVoiceSelected(selectedVoice || null);
      console.log(`Updated selected voice for language ${language}:`, selectedVoice);
    };

    // Ensure voices are updated
    window.speechSynthesis.onvoiceschanged = updateVoices;

    // Call updateVoices to initialize
    updateVoices();
  }, [language]); // Run the effect when language changes

  const speakText = (text: string) => {
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance(text);

      if (voiceSelected) {
        speech.voice = voiceSelected;
        console.log('Using selected voice:', voiceSelected.name);
      } else {
        console.log('Selected voice not found for language:', language);
        // Fallback to default English voice
        const fallbackVoice = voices.find(voice => voice.lang === 'en-US');
        if (fallbackVoice) {
          speech.voice = fallbackVoice;
          console.log('Fallback to default voice:', fallbackVoice.name);
        }
      }

      // Set the language of the SpeechSynthesisUtterance object
      speech.lang = language === 'vi' ? 'vi-VN' :
                    (language === 'en' ? 'en-US' :
                    (language === 'ko' ? 'ko-KR' : 'en-US')); // Default to English if language is not recognized

      window.speechSynthesis.speak(speech);
      console.log('Speaking text:', text);
    } else {
      console.log('SpeechSynthesis is not supported in this browser.');
    }
  };

  return { speakText };
};

export default useSpeechSynthesis;
