import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from './action/product';
import wordformReducer from './action/grammar/wordform';
import topicReducer from './action/topic';
import  vocabReducer from './action/vocab';


export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  product:productReducer,
  wordform:wordformReducer,
  topic:topicReducer,
  vocab:vocabReducer,

});

export default rootReducer;
