import React from "react";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";

import { useTranslation } from "react-i18next";
import VocabForm from "./VocabForm";

function VocabCreate() {
    const {t} = useTranslation()
  return (
    <div>
      <TitleComponent title={t('add')} isExist={false} />
      <VocabForm/>
    </div>
  );
}

export default VocabCreate;
