import { AxiosResponse } from "axios";
import { deleteAsync, getAsync, postAsync } from "./client";

class ProductService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/product', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Re-throwing the error to propagate it
    }
  }

  async getById(_id: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/product/${_id}`);
      return response;
    } catch (error) {
      console.error("Error fetching product by ID:", error);
      throw error; // Re-throwing the error to propagate it
    }
  }

  async post(productData: any): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/product', productData);
      return response;
    } catch (error) {
      console.error("Error posting product data:", error);
      throw error; // Re-throwing the error to propagate it
    }
  }

  async delete(_id: string): Promise<AxiosResponse> {
    try {
      const response = await deleteAsync(`/product/${_id}`);
      return response;
    } catch (error) {
      console.error("Error deleting product:", error);
      throw error; // Re-throwing the error to propagate it
    }
  }
}

// Create an instance of the ProductService class
const productServiceInstance = new ProductService();

// Export the instance
export default productServiceInstance;
