import { Col, Flex, Radio, Row } from "antd";
import React from "react";
import { Input } from "antd";
const { Search } = Input;
function Dashboard() {
  return (
    <div>
      <div>
        <h1
          style={{
            maxWidth: "569px",
            marginInline: "auto",
            textAlign: "center",
          }}
        >
          Search Among{" "}
          <span style={{ color: `var(--primary-color)` }}>58340</span> Courses
          And Find Your Favorite Course
        </h1>
        <div style={{ maxWidth: "569px", margin: "20px auto" }}>
          <Search placeholder="Input search text" enterButton />
        </div>
      </div>
      <Row>
        <Col xl={12}>
          <p style={{ fontWeight: 500, fontSize: 17 }}>New courses</p>
        </Col>
        <Col  xl={12}>
          <Flex justify="flex-end">
            <Radio.Group defaultValue="a" size="large" style={{gap:20}}>
              <Radio.Button value="a">Hangzhou</Radio.Button>
              <Radio.Button value="b">Shanghai</Radio.Button>
              <Radio.Button value="c">Beijing</Radio.Button>
              <Radio.Button value="d">Chengdu</Radio.Button>
            </Radio.Group>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
