import { Card, Col, Form, Input, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ButtonBottom from "../../global/item/ButtonComponent/ButtonBottom";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { dispatch, useSelector } from "../../redux/store";
import { getWordform } from "../../redux/action/grammar/wordform";
import { createVocab } from "../../redux/action/vocab";

import { openNotification } from "../../global/item/Notification/Notification";
import { getTopicById } from "../../redux/action/topic";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
function VocabForm() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [uploadedImage, setUploadedImage] = useState<Blob | null>(null);

  const { wordformList } = useSelector((state) => state.wordform);
  const { topicList } = useSelector((state) => state.topic);
  const [id, setId] = useState<string | null>(null);


  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedValuesTopic, setSelectedValuesTopic] = useState<string[]>([]);


  const [form] = Form.useForm();
  const fetchData = useCallback(async () => {
    const storedId = localStorage.getItem("id");
    if (storedId) {
      setId(storedId);
      await dispatch(getTopicById(storedId));
    }
    dispatch(getWordform({}));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCancel = () => {
    navigate(-1)
  };
  const handleSubmit = async () => {

    try {
      const values = await form.validateFields();
      const formData = new FormData();
  
      formData.append("name", values.name || "");
      formData.append("transcription", values.transcription || "");
      formData.append("meaning", values.meaning || "");
  
     
      if (Array.isArray(selectedValues)) {
        selectedValues.forEach((id: string) => {
          formData.append("category[]", id);
        });
      }
      
      if (Array.isArray(selectedValuesTopic)) {
        selectedValuesTopic.forEach((topicId: string) => {
          formData.append("topic[]", topicId);
        });
      } else if (values.topic) {
        formData.append("topic[]", values.topic);
      }
  
      formData.append("synonym", values.synonym || "");
      formData.append("antonym", values.antonym || "");
      formData.append("phrase", values.phrase || "");
      formData.append("context", values.context || "");
      formData.append("example", values.example || "");
      formData.append("note", values.note || "");
  
      // Handle user ID
      if (id) {
        formData.append("user", id);
      }
  
      // Handle image file
      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }
  
      // Dispatch the action
      const resultAction = await dispatch(createVocab(formData));
  
      if (createVocab.fulfilled.match(resultAction)) {
        openNotification({
          type: "success",
          message: t("success"),
          description: t("successCreate"),
        });
      } else {
        // Handle errors
        openNotification({
          type: "error",
          message: t("error"),
          description: t("errorCreate"),
        });
        console.error("Failed to submit data:", resultAction.payload || resultAction.error.message);
      }
    } catch (error) {
      // Handle validation or unexpected errors
      console.error("Validation failed:", error);
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorCreate"),
      });
    } finally {
      
    }
  };
  

  
  const handleCloseImg = () => {
    setUploadedImage(null);
  };

  const handleFileChange = (info: any) => {
    const file = info.file.originFileObj;
    if (file instanceof Blob) {
      setUploadedImage(file); 
    }
  };
  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };
  const handleSelectChangeTopic = (values: string[]) => {
    setSelectedValuesTopic(values);
  };
  const options = wordformList.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const optionsTopic = topicList.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  return (
    <Form
    form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
      requiredMark={false}
      onFinish={handleSubmit}
    >
      <Card className="layoutItem">
        <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
          {t("basic_information")}
        </h3>
        <Row gutter={{ xs: 12, xl: 20 }}>
          <Col xl={12} xs={24}>
            <Form.Item
              label={t("vocab")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("please_input_your_data"),
                },
              ]}
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Row gutter={{ xs: 12, xl: 20 }}>
              <Col xl={12} xs={12}>
                <Form.Item
                  label={t("word_type")}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: t("please_input_your_data"),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    value={selectedValues}
                    placeholder="Chọn từ loại"
                    className="selectForm"
                    style={{ width: "100%" }}
                    onChange={handleSelectChange}
                  >
                    {options.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  label={t("topic")}
                  name="topic"
                
                >
                  <Select
                    mode="multiple"
                    value={selectedValuesTopic}
                    placeholder="Chọn chủ đề"
                    className="selectForm"
                    style={{ width: "100%" }}
                    onChange={handleSelectChangeTopic}
                  >
                    {optionsTopic.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("transcrip")}
              name="transcription"
              rules={[
                {
                  required: true,
                  message: t("please_input_your_data"),
                },
              ]}
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("meaning")}
              name="meaning"
              rules={[
                {
                  required: true,
                  message: t("please_input_your_data"),
                },
              ]}
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="layoutItem">
        <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
          {t("additional_information")}
        </h3>
        <Row gutter={{ xs: 12, xl: 20 }}>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("synonymo")}
              name="synonym"
             
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("antonym")}
              name="antonym"
              
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("phrase")}
              name="phrase"
              
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item
              label={t("context")}
              name="context"
              
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item label={t("example")} name="example">
              <Input.TextArea
                placeholder={t("enter_data_name")}
                style={{ minHeight: 42 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card className="layoutItem">
        <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
          {t("additional_information")}
        </h3>
        <Row gutter={[20, 0]}>
          <Col xl={12} xs={24}>
            <Form.Item label={t("note")} name="note">
              <Input.TextArea
                placeholder={t("enter_data_name")}
                className="input__textArea"
              />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
                {uploadedImage ? (
                  <Col xl={24}>
                    <div>
                      <CloseOutlined
                        style={{
                          position: "absolute",
                          right: 24,
                          top: 12,
                          zIndex: 999,
                          fontSize: 16,
                        }}
                        className="iconCustom"
                        onClick={handleCloseImg}
                      />
                      <img
                        src={URL.createObjectURL(uploadedImage)}
                        alt={t("uploaded_image")}
                        style={{
                          border: `var(--border-line)`,
                          borderRadius: 10,
                          maxWidth: "100%",
                          padding: 10,
                          background: "rgba(0, 0, 0, 0.02)",
                          position: "relative",
                        }}
                      />
                    </div>
                  </Col>
                ) : (
                  <Form.Item name="image" label={t("image")}>
                    <Dragger
                      name="file"
                      multiple={false}
                      showUploadList={false}
                      onChange={handleFileChange}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t("upload_text")}</p>
                      <p className="ant-upload-hint">{t("upload_hint")}</p>
                    </Dragger>
                  </Form.Item>
                )}
              </Col>
        </Row>
      </Card>
      <ButtonBottom
        onCancelClick={handleCancel}
        btnCancel={t("cancel")}
        btnContinue={t("continue")}
      />
    </Form>
  );
}

export default VocabForm;
