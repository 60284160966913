import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import { LanguageProvider } from './component/context/Language';
import i18n from './component/context/translation';

ReactDOM.render(
  <LanguageProvider>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </LanguageProvider>,
  document.getElementById('root')
);

reportWebVitals();
