import React, { useState } from "react";
import {
  Affix,
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Flex,
  Layout,
  Menu,
  Popover,
  theme,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined,
  SettingOutlined,
  LoginOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import StickyBox from "react-sticky-box";
import SliderApp from "./Slider/Slider";
import { Outlet } from "react-router-dom";
import logoApp from "../../../accset/logoApp.png";
import iconLogo from "../../../accset/iconLogo.png";
import { useTranslation } from "react-i18next";
import UseRespon from "../../../hook/useResponsive";

import Translation from "../item/Translation/Translation";
import LottieAvatar from "../Custom/Avatar/Avatar";
import { useAuth } from "../../context/AuthContext";

const { Header, Sider, Content } = Layout;

const LayoutApp = () => {
  const { isMobile } = UseRespon();
  const {t} = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("home");
  const { email, username } = useAuth();

  const handleMenuClick = (key: any) => {
    setSelectedKey(key);
  };
  const handleLogout = () =>{
    localStorage.clear();
    window.location.href = '/sign-in';
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item (disabled)
        </a>
      ),
      // icon: <SmileOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];
  const content = (
    <div>
<div style={{}}>

<Menu>
    <Menu.Item key="1">
      <a href="/profile">{t('profile')}</a>
    </Menu.Item>
    <Menu.Item key="2">
      <a href="/settings">{t('schedule')}</a>
    </Menu.Item>
    <Menu.Item key="3">
      {t('history')}
    </Menu.Item>
  </Menu>


    </div>
    <div style={{padding:20,borderTop:"1px dashed  #ccc"}}>

      <Button type="primary" danger style={{width:"100%",}} onClick={handleLogout}><LoginOutlined />{t('logout')}</Button>
    </div>
    </div>
    
  );
  const customTitle = (
    <div style={{borderBottom:"1px dashed  #ccc",padding:20}}>
      <h3 style={{fontWeight:500}}>{username}</h3>
      <p style={{color:"#b6b6b6",margin:1, fontWeight:400}}>{email}</p>
    </div>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!isMobile && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={264}
          style={{ background: "#fff", borderInlineEnd: `var(--border-line)` }}
        >
          <StickyBox offsetTop={10}>
            <div
              className="demo-logo-vertical"
              style={{
                height: "50px",
                borderRadius: "10px",
                margin: "10px 4px",
              }}
            >
              <div
                style={
                  collapsed
                    ? {
                        background: `url(${iconLogo}) no-repeat center center`,
                        width: "100%",
                        height: "100%",
                      }
                    : {
                        background: `url(${logoApp}) no-repeat center center`,
                        width: "100%",
                        height: "100%",
                      }
                }
              ></div>
            </div>
            <SliderApp selectedKey={selectedKey} onClick={handleMenuClick} />
          </StickyBox>
        </Sider>
      )}

      <Layout>
        <Affix offsetTop={0}>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              paddingInline: isMobile ? 16 : 46,
              height: 64,
              borderBottom: `var(--border-line)`,
            }}
          >
            <Flex
              justify="space-between"
              align="center"
              style={{ height: "100%" }}
            >
              <Flex>
                {isMobile ? (
                  <Button
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                  />
                ) : (
                  <Button
                    type="text"
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    className="iconCustom"
                  />
                )}
              </Flex>
              <Flex align="center" gap={isMobile ? 4 : 14}>
                <Translation />

                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <BellOutlined className="iconCustom" />
                </Dropdown>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <SettingOutlined className="iconCustom" />
                </Dropdown>
                <Popover content={content} title={customTitle}
               
                >
                  <Avatar
                   
                    size={39}
                    style={{ backgroundColor: 'transparent', border: 'none' }} 
                  >
                    <LottieAvatar size={39} />
                  </Avatar>
                </Popover>
              </Flex>
            </Flex>
          </Header>
        </Affix>

        <Content
          style={{
            padding: isMobile ? "0px 16px 24px 16px" : "0px 46px 24px 46px",
            minHeight: 280,
            background: "#fff",
          }}
        >
          <Outlet /> {/* Render child routes here */}
        </Content>
      </Layout>

      <Drawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        placement={"left"}
        size="large"
        width={"72%"}
      >
        <SliderApp
          selectedKey={selectedKey}
          onClick={(key: any) => {
            handleMenuClick(key);
            onClose();
          }}
        />
      </Drawer>
    </Layout>
  );
};

export default LayoutApp;
