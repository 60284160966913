const vn = {
    "layout": {
      "dashboard": "Bản điều khiển",
      "home": {
        "home": "Trang chủ",
        "banner": "Truyền thông",
      },
      "banner": {
        "subSection1": "Phần con 1",
        "subSection2": "Phần con 2"
      },
      "success": "Thành công",
      "updateSuccess": "Dữ liệu được cập nhật thành công!"
    },
    "home": {
  
    },
    "edit":"Chỉnh sửa",
    "basicInformation":"Thông tin cơ bản",
    "tag":"Nhãn",
    "title":"Tiêu đề",
    "describtion":"Mô tả",
    "imageInfo":"Thông tin ảnh",
    "vocab":"Từ vựng",
    "add":"Tạo mới",
    "topic":"Chủ đề",
    "welcome":"Xin chào",


    "signInAccount":"Đăng nhập tài khoản của bạn",
    "password":"Mật khẩu",
    "email":"Email",
    "emailRequired":"Vui lòng nhập email!",
    "emailGmailRequired":"Vui lòng nhập đúng định dạng email",
    "passwordRequired":"Vui lòng nhập mật khẩu!",
    "usernameRequired":"Vui lòng nhập tài khoản!",
    "forgotPass":"Quên mật khẩu",
    "youForgot":"Bạn quên mật khẩu ư?",
    "forgotPassDesc": "Đừng lo lắng, vui lòng nhập email của bạn để chúng tôi có thể hỗ trợ bạn cập nhật mật khẩu.",
    "signIn":"Đăng nhập",
    "noAccount":"Bạn chưa có tài khoản?",
    "registerNow":"Đăng kí ngay",
    "or":"Hoặc",
    "username":"Họ và tên",
    "signUpAccount":"Đăng ký tài khoản cho bạn",
    "useAccount":"Bạn đã có tài khoản?",
    "loginNow":"Đăng nhập ngay",
    "signUp":"Đăng ký",
    "otpSentToEmail":"Mã OTP đã được gửi đến email của bạn",
    "emailExists":"Email đã tồn tại",
    "useAnotherEmail":"Vui lòng sử dụng email khác",
    "success":"Thành công",
  "error":"Thất bại",
  "LoginSuccess":"Đăng nhập thành công",
  "loginFailure":"Đăng nhập thất bại",
  "registerSuccess":"Đăng kí thành công",
  "errorForgot":"Email không tồn tại hoặc tài khoản google không thể reset",
  "confirm":"Xác nhận",
  "verifyAccount": "Xác minh tài khoản của bạn",
  "verifyDesc": "Vui lòng nhập mã xác minh từ email của bạn",
  "otp": "Mã OTP",
  "otpRequired": "Vui lòng nhập mã OTP của bạn!",
  "verifySuccess": "Xác minh thành công",
  "verifyFailure": "Xác minh thất bại",
  "tokenError":"Token hết hạn hoặc không hợp lệ",
  "grammar":"Ngữ pháp",
  "wordform":"Từ loại",
  basic_information: "Thông tin cơ bản",
  additional_information: "Thông tin bổ sung",
  word_type: "Từ loại",
  symbol: "Kí hiệu",
  enter_word_type_name: "Nhập tên từ loại",
  enter_data_name: "Nhập dữ liệu",
  please_input_your_data: "Vui lòng nhập dữ liệu!",
  image: "Hình ảnh",
  please_select_an_image: "Vui lòng chọn ảnh!",
  uploaded_image: "Ảnh đã tải lên",
  upload_text: "Nhấp hoặc kéo tệp vào khu vực này để tải lên",
  upload_hint: "Hỗ trợ tải lên một tệp duy nhất. Nhấp hoặc kéo tệp vào đây.",
  cancel: "Hủy bỏ",
  continue: "Tiếp tục",
  successCreate:"Tạo thành công",
  errorCreate:"Tạo thất bại",
  searchItem:"Tìm kiếm của bạn...",
  review:"Ôn tập",
  transcrip:"Phiên âm",
  meaning:"Nghĩa của từ",
  synonymo:"Từ đồng nghĩa",
  antonym:"Từ trái nghĩa",
  phrase:"Cụm từ đi kèm",
  context:"Ngữ cảnh sử dụng",
  example:"Ví dụ",
  note:"Ghi chú",
  logout:"Đăng xuất",
  profile:"Tài khoản của bạn",
  schedule:"Đặt lịch học",
  history:"Lịch sử",
  
  };
  
  export default vn;
  