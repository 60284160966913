import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WordformState } from "../../../@type/grammar/wordform";
import wordform from "../../../service/grammar/wordform";

export const getWordform = createAsyncThunk(
  "get/getWordform",
  async (params: any) => {
    const { data } = await wordform.get(params);
    return data;
  }
);
export const createWordform = createAsyncThunk(
    "get/createWordform",
    async (formData: FormData) => {
      const { data } = await wordform.post(formData);
      return data;
    }
  );
  
const initialState: WordformState = {
  wordformList: [],

};

const wordformSlice = createSlice({
  name: "wordform",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWordform.fulfilled, (state, action) => {
      state.wordformList = action.payload;
    });
    builder.addCase(getWordform.fulfilled, (state, action) => {
      state.wordformList = action.payload.categorys;
    });
  },
});

export default wordformSlice.reducer;
