import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { openNotification } from "../global/item/Notification/Notification";
import { useTranslation } from "react-i18next";

// Helper function to decode base64url
const base64urlDecode = (str: string) => {
  return decodeURIComponent(
    atob(str.replace(/-/g, '+').replace(/_/g, '/'))
      .split('')
      .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  );
};

// Interface for decoded token
interface DecodedToken {
  id?: string;
  email?: string;
  username?: string;
  [key: string]: any;
}

interface AuthContextType {
  hasValidToken: boolean;
  setHasValidToken: (valid: boolean) => void;
  validateToken: () => void;
  errorMessage: string | null;
  clearErrorMessage: () => void;
  email: string | null;
  username: string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [hasValidToken, setHasValidToken] = useState<boolean>(() => {
    const token = localStorage.getItem("token");
    const isTokenValidated = localStorage.getItem("isTokenValidated");
    return !!token && isTokenValidated === "true";
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();

  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");

  // Function to save token details in localStorage
  const saveTokenDetails = useCallback((token: string) => {
    const parts = token.split(".");
    if (parts.length === 3) {
      const payload = parts[1];
      try {
        const decoded = JSON.parse(base64urlDecode(payload)) as DecodedToken;
        const { id, email, username } = decoded;
        if (id) localStorage.setItem("id", id);
        if (email) localStorage.setItem("email", email);
        if (username) localStorage.setItem("username", username);
      } catch (err) {
        console.error("Error decoding token payload", err);
      }
    }
  }, []);

  // Function to validate token
  const validateToken = useCallback(async (forceValidate: boolean = false) => {
    const token = localStorage.getItem("token");

    if (token) {
      const isTokenValidated = localStorage.getItem("isTokenValidated");

      if (isTokenValidated === "true" && !forceValidate) {
        setHasValidToken(true);
        return;
      }

      try {
        const response = await fetch(
          "https://be-elearning.onrender.com/user/verify-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          setHasValidToken(true);
          localStorage.setItem("isTokenValidated", "true");
          setErrorMessage(null);
          saveTokenDetails(token); // Save details after token validation
        } else {
          setHasValidToken(false);
          openNotification({
            type: "error",
            message: t("error"),
            description: t("tokenError"),
          });
          setErrorMessage("Invalid token. Please log in again.");
          localStorage.removeItem("token");
          localStorage.removeItem("isTokenValidated");
          localStorage.removeItem("id");
          localStorage.removeItem("email");
          localStorage.removeItem("username");
        }
      } catch (error) {
        console.error("Token validation failed", error);
        setHasValidToken(false);
        setErrorMessage("Token validation failed. Please try again.");
        localStorage.removeItem("token");
        localStorage.removeItem("isTokenValidated");
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        localStorage.removeItem("username");
      }
    } else {
      setHasValidToken(false);
      setErrorMessage("No token found. Please log in.");
    }
  }, [saveTokenDetails, t]);

  useEffect(() => {
    validateToken();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "token") {
        validateToken(true); // Re-validate if token changes
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [validateToken]);

  useEffect(() => {
    if (!hasValidToken) {
      validateToken();
    }
  }, [hasValidToken, validateToken]);

  const clearErrorMessage = () => setErrorMessage(null);

  return (
    <AuthContext.Provider
      value={{
        hasValidToken,
        setHasValidToken,
        validateToken,
        errorMessage,
        clearErrorMessage,
        email,
        username,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
