import React, { useEffect } from 'react';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from '../../../redux/store';
import { getVocab } from '../../../redux/action/vocab';
import useSpeechSynthesis from '../../../context/speech';

const { Panel } = Collapse;

function CardComponent() {
  const dispatch = useDispatch();
  const { vocabList } = useSelector((state) => state.vocab);
  const { speakText } = useSpeechSynthesis();

  useEffect(() => {
    dispatch(getVocab({}));
  }, [dispatch]);

  const items = vocabList.map((vocab) => ({
    key: vocab._id,
    label: `${vocab.name} (${vocab.transcription})`,
    children: (
      <div>
        <p><strong>Meaning:</strong> {vocab.meaning}</p>
        <p><strong>Example:</strong> {vocab.example}</p>
        <p><strong>Transcription:</strong> {vocab.transcription}</p>
      </div>
    ),
    textToRead: vocab.name
  }));

  const handleCollapseChange = (key: any) => {
    console.log('Collapse changed:', key);
    if ('speechSynthesis' in window) {
      const activePanel = items.find(item => item.key === key[key.length - 1]);
      if (activePanel) {
        console.log('Speaking:', activePanel.textToRead);
        speakText(activePanel.textToRead);
      } else {
        console.log('Active panel not found.');
      }
    } else {
      console.log('SpeechSynthesis is not supported in this browser.');
    }
  };

  return (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={handleCollapseChange}
      className="custom-collapse"
      style={{background:"none",border:"none", margin:"20px 0"}}
    >
      {items.map((item) => (
        <Panel header={item.label} key={item.key}>
          {item.children}
        </Panel>
      ))}
    </Collapse>
  );
}

export default CardComponent;
