import React from "react";
import { Menu } from "antd";
import "./Slider.scss";
import { useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  AppstoreOutlined,
  CloudOutlined,
  CalendarOutlined,
  PicCenterOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const SliderApp = ({ onClick }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMenuClick = ({ key }: any) => {
    onClick(key);
    navigate(`/${key}`);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["1"]}
      onClick={handleMenuClick}
      className="sliderApp"
    >
      <Menu.Item key="Dashboard" icon={<HomeOutlined />}>
        Dashboard
      </Menu.Item>

      <Menu.Item icon={<CalendarOutlined />} key="Vocab">
        {t("vocab")}
      </Menu.Item>
      <Menu.Item icon={<PicCenterOutlined />} key="Topic">
        {t("topic")}
      </Menu.Item>
      <SubMenu
        key="grammar"
        style={{ background: "none" }}
        icon={<AppstoreOutlined />}
        title={t("grammar")}
      >
        <Menu.Item key="Grammar/Wordform" className="menuitemSub">
          {t("wordform")}
        </Menu.Item>
        <Menu.Item key="home2" className="menuitemSub">
          Home 2
        </Menu.Item>
      </SubMenu>
      <Menu.Item icon={<CloudOutlined />} key="Review">
        {t("review")}
      </Menu.Item>
    </Menu>
  );
};

export default SliderApp;
