import React from 'react';
import Countdown from 'react-countdown';

// Component hiển thị khi thời gian đếm ngược đã hết
const Completionist = () => (
  <span style={{ fontSize: '2rem', color: '#fff', fontWeight: 'bold' }}>
    Đã đến lúc!
  </span>
);

// Custom renderer cho Countdown
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: '20px'
      }}>
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: '#fff', 
          borderRadius: '10px', 
          padding: '10px 20px', 
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
        }}>
          <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#333' }}>{days}</div>
          <div style={{ color: '#777' }}>Ngày</div>
        </div>
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: '#fff', 
          borderRadius: '10px', 
          padding: '10px 20px', 
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
        }}>
          <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#333' }}>{hours}</div>
          <div style={{ color: '#777' }}>Giờ</div>
        </div>
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: '#fff', 
          borderRadius: '10px', 
          padding: '10px 20px', 
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
        }}>
          <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#333' }}>{minutes}</div>
          <div style={{ color: '#777' }}>Phút</div>
        </div>
        <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          backgroundColor: '#fff', 
          borderRadius: '10px', 
          padding: '10px 20px', 
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
        }}>
          <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#333' }}>{seconds}</div>
          <div style={{ color: '#777' }}>Giây</div>
        </div>
      </div>
    );
  }
};

function Review() {
  // Ngày và giờ bắt đầu: 00:00 ngày 27/8
  const startDate = new Date('2024-08-27T00:00:00');
  
  // Thời gian kết thúc là 14 ngày sau
  const endDate = new Date(startDate.getTime() + 14 * 24 * 60 * 60 * 1000);

  return (
    <div style={{
      width: "100%", 
      height: "100%", 
      background: "#f0f0f0", 
      marginTop: 12, 
      display: "flex", 
      flexDirection: "column",
      justifyContent: "center", 
      alignItems: "center", 
      textAlign: "center", 
      padding: 20,
      position: 'relative'
    }}>
      <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        padding: '20px', 
        borderRadius: '10px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff'
      }}>
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ marginBottom: 20, fontSize: '2.5rem', fontWeight: 'bold' }}>
            Tôi cần dữ liệu của bạn để phát triển thêm
          </h1>
          <Countdown date={endDate} renderer={renderer} />
        </div>
      </div>
    </div>
  );
}

export default Review;
