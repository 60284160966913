// src/App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./sass/index.scss";
import { Provider } from "react-redux";
import { store } from "./component/redux/store";
import AppRoutes from "./router/routerApp";
import { AuthProvider } from "./component/context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  return (
    <Provider store={store}>
        <GoogleOAuthProvider clientId="931145539321-43unknst7fqum73p7ue91hgbo60b9a1b.apps.googleusercontent.com">
        <AuthProvider>
          <Router>
            <AppRoutes />
          </Router>
        </AuthProvider>
    </GoogleOAuthProvider>
      </Provider>
  );
}

export default App;
