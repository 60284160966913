import React from "react";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";

import TopicForm from "./TopicForm";

function TopicCreate() {
  const { t } = useTranslation();
  return (
    <div>
      <TitleComponent title={t("add")} isExist={false} />
      <TopicForm />
    </div>
  );
}

export default TopicCreate;
