import { useState, useEffect } from 'react';

const base64urlDecode = (str: string) => {
  return decodeURIComponent(
    atob(str.replace(/-/g, '+').replace(/_/g, '/'))
      .split('')
      .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  );
};

interface DecodedToken {
  id?: string;
  [key: string]: any;
}

const useDecodeToken = () => {
  const [id, setId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const parts = token.split('.');
      if (parts.length === 3) {
        const payload = parts[1];
        try {
          const decoded = JSON.parse(base64urlDecode(payload)) as DecodedToken;
          setId(decoded.id || null);
        } catch (err) {
          setError('Error parsing payload: ' + (err as Error).message);
        }
      } else {
        setError('Invalid token structure');
      }
    } else {
      setError('Token not found');
    }
  }, []);

  return { id, error };
};

export default useDecodeToken;
