import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../language/en'; // Adjust paths as necessary
import vn from '../language/vn';
import ko from '../language/ko';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      vn: {
        translation: vn,
      },
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
    },
    lng: 'vn', // Default language
    fallbackLng: 'vn',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
