import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Input, Row } from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { dispatch, useSelector } from "../../redux/store";
import UseRespon from "../../../hook/useResponsive";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { getTopicById } from "../../redux/action/topic";

// Debounce function
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

function TopicList() {
  const { t } = useTranslation();
  const { topicList } = useSelector((state) => state.topic);
  const [id, setId] = useState<string | null>(null);
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = UseRespon().isMobile ? 2 : 6;
  const [isFocused, setIsFocused] = useState(false);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    const storedId = localStorage.getItem('id');
    if (storedId) {
      setId(storedId);
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getTopicById(id));
    }
  }, [id]); // Loại bỏ 'dispatch' khỏi mảng phụ thuộc

  // Memoize filtered and paginated items
  const filteredItems = useMemo(() => topicList.filter(item =>
    item.name.toLowerCase().includes(debouncedValue.toLowerCase())
  ), [topicList, debouncedValue]);

  const currentItems = useMemo(() => filteredItems.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  ), [filteredItems, currentPage, itemsPerPage]);

  const handleChange = useCallback((e: any) => {
    setValue(e.target.value);
    setCurrentPage(0); // Reset to the first page when the search value changes
  }, []);

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  const handleNextPage = useCallback(() => {
    if ((currentPage + 1) * itemsPerPage < filteredItems.length) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, filteredItems.length, itemsPerPage]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  return (
    <div>
      <TitleComponent
        title={t("topic")}
        type="primary"
        data={t("add")}
        to="/Topic/create"
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Input
          value={value}
          onChange={handleChange}
          placeholder={isFocused ? t('searchItem') : ""}
          prefix={
            <SearchOutlined
              style={{
                color: isFocused ? 'blue' : 'black',
              }}
            />
          }
          style={{
            width: UseRespon().isMobile ? "50%" : "32%",
            boxShadow: "none",
            border: "none",
            borderRadius: 0,
            borderBottom: isFocused ? "1px solid #d9d9d9" : "none",
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            className="buttonPointer"
            icon={<ArrowLeftOutlined />}
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
          />
          <Button
            className="buttonPointer"
            icon={<ArrowRightOutlined />}
            onClick={handleNextPage}
            disabled={(currentPage + 1) * itemsPerPage >= filteredItems.length}
          />
        </div>
      </div>
      <Row gutter={[20, 5]}>
        <TransitionGroup component={null}>
          {currentItems.map((item) => (
            <CSSTransition
              key={item._id} // Use a unique key instead of index
              timeout={500}
              classNames="fade" // Define CSS classes for transitions
            >
              <Col xl={8}>
                <Card className="layoutItem cardItem">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="image-container">
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                    <div style={{ flexDirection: "column" }}>
                      <h3 style={{ margin: 0 }}>{item.name}</h3>
                    </div>
                  </div>
                </Card>
              </Col>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Row>
    </div>
  );
}

export default TopicList;
