import { Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { dispatch } from "../../redux/store";
import { openNotification } from "../../global/item/Notification/Notification";
import Loading from "../../global/item/Loading/loading";
import ButtonBottom from "../../global/item/ButtonComponent/ButtonBottom";
import useDecodeToken from "../../../hook/tokenDecode";
import { createTopic } from "../../redux/action/topic";

function TopicForm() {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState<Blob | null>(null);
  const { id } = useDecodeToken();
  console.log('====================================');
  console.log("id",id);
  console.log('====================================');
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setUploadedImage(null);
    navigate(-1);  
  };

  const handleFileChange = (info: any) => {
    const file = info.file.originFileObj;
    if (file instanceof Blob) {
      setUploadedImage(file); 
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const values = await form.validateFields();
      const formData = new FormData();
  
      formData.append("name", values.name || "");
  
      if (id) {
        formData.append("userId", id);
      }
  
      // Handle image file
      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }
  
      // Dispatch the action
      const resultAction = await dispatch(createTopic(formData));
  
      if (createTopic.fulfilled.match(resultAction)) {
        openNotification({
          type: "success",
          message: t("success"),
          description: t("successCreate"),
        });
      } else {
        // Handle errors
        openNotification({
          type: "error",
          message: t("error"),
          description: t("errorCreate"),
        });
        console.error("Failed to submit data:", resultAction.payload || resultAction.error.message);
      }
    } catch (error) {
      // Handle validation or unexpected errors
      console.error("Validation failed:", error);
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorCreate"),
      });
    } finally {
      
    }
  };
  

  const handleCloseImg = () => {
    setUploadedImage(null);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Card className="layoutItem">
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              {t("basic_information")}
            </h3>
            <Row gutter={{ xs: 12, xl: 20 }}>
              <Col xl={24} xs={24}>
              <Form.Item
              label={t("topic")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("please_input_your_data"),
                },
              ]}
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
              </Col>
              <Col xl={24} xs={24}>
                {uploadedImage ? (
                  <Col xl={24}>
                    <div>
                      <CloseOutlined
                        style={{
                          position: "absolute",
                          right: 24,
                          top: 12,
                          zIndex: 999,
                          fontSize: 16,
                        }}
                        className="iconCustom"
                        onClick={handleCloseImg}
                      />
                      <img
                        src={URL.createObjectURL(uploadedImage)}
                        alt={t("uploaded_image")}
                        style={{
                          border: `var(--border-line)`,
                          borderRadius: 10,
                          maxWidth: "100%",
                          padding: 10,
                          background: "rgba(0, 0, 0, 0.02)",
                          position: "relative",
                        }}
                      />
                    </div>
                  </Col>
                ) : (
                  <Form.Item name="image" label={t("image")}>
                    <Dragger
                      name="file"
                      multiple={false}
                      showUploadList={false}
                      onChange={handleFileChange}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t("upload_text")}</p>
                      <p className="ant-upload-hint">{t("upload_hint")}</p>
                    </Dragger>
                  </Form.Item>
                )}
              </Col>
             
            </Row>
          </Card>

       
          <ButtonBottom
            onCancelClick={handleCancel}
            btnCancel={t("cancel")}
            btnContinue={t("continue")}
          />
        </Form>
      )}
    </div>
  );
}

export default TopicForm;
